@media (max-width: 1400px) {
  .info-box__img{
    position: relative;
    width: 100%;
    right: 0;
  }
}

@media (max-width: 1200px) {
  .container {
    max-width: 1230px;
  }
}

@media (max-width: 992px) {
  .navbar__menu--link{
    font-size: 14px;
  }

  .top-line-text{
    text-align: center;
    margin-bottom: 1rem!important;
  }

  .footer-nav{
    margin: 1rem 0;
  }

  .footer-copyright{
    text-align: center;
  }

  .footer-made-in{
    text-align: center;
  }

  .info-box{
    align-items: center;
  }

  .pluses-item{
    width: 45%;
  }

  .pluses-card{
    width: 100%;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin-bottom: 1rem;
  }

  .pluses-card__num{
    position: relative;
    left: 0;
  }

  .contacts-box{
    margin-top: 1.5rem;
  }

  .form-control{
    margin-bottom: 1rem!important;
  }

}

@media (max-width: 800px) {
  .navbar__menu{
    display: none;
  }

  .mobile-menu-btn{
    display: block;
  }

  .info-box{
    flex-flow: row wrap;
    padding: 1rem;
  }

  .info-box__text{
    width: 100%;
  }

  .info-box__img{
    margin-top: 1.5rem;
  }

  .get-service-info{
    margin-bottom: 2rem;
  }

  .get-service-box{
    width: 100%;
    transform: scale(1);
  }

  .get-service-title{
    width: 100%;
  }

  .get-service-text{
    width: 100%;
  }

  .works-img{
    margin-bottom: 30px;
  }
}

@media (max-width: 576px) {
  .header__contacts-row{
    flex-flow: row wrap;
    .btn {
      width: 100%;
      margin-top: 1rem;
    }
  }

  .page-title__accent{
    font-size: 2rem;
  }

  .page-subtitle{
    width: 100%;
  }

  .footer-menu__item{
    width: 45%;
    margin-bottom: 1rem;
  }

  .footer-menu__link{
    font-size: 18px;
  }

  .pluses-item{
    width: 100%;
  }

  .article__img, .article__content{
    width: 100%;
  }

  .article__content{
    margin-top: 1.5rem;
  }

  .product-service-box{
    ul {
      li {
        font-size: 12px;
      }
    }
  }

  .about-img-1, .about-row-2-img{
    display: none;
  }

  .plus-box{
    margin-left: 0!important;
  }

  .inner-info{
    -webkit-background-size: cover;
    background-size: cover;
  }
}