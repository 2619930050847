$font-stack: -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", "Helvetica Neue", sans-serif;

@import "~bootstrap/scss/bootstrap";

@font-face {
  font-family: 'Good Vibes Pro';
  src: url('../fonts/GoodVibesPro.woff2') format('woff2'),
  url('../fonts/GoodVibesPro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
  url('../fonts/ProximaNova-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('../fonts/Merriweather-Black.woff2') format('woff2'),
  url('../fonts/Merriweather-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

body {
  font-family: 'Proxima Nova Rg';
}

.header__logo{
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.header-row{
  font-size: 14px;
  border-top: 1px solid #bbbbbb;
  padding: .5rem 0;
  p {
    margin: 0;
  }
}

.header__contacts-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dot {
  width: 6px;
  height: 6px;
  background: #777777;
  border-radius: 50% ;
}

.btn-secondary{
  background: #7f919e;
  border: 1px solid #656c7a;
  border-bottom: 2px solid #656c7a;
}

.btn-primary{
  background: #d05252;
  border: 1px solid #ae2b2b;
  border-bottom: 2px solid #ae2b2b;
}

.navbar{
  background: #2d2d2f;
  display: block;
  padding: 0;
  .row {
    width: 100%;
  }
}

.navbar__menu{
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.navbar__menu--link{
  color: #fff;
  display: block;
  padding: 1rem 2.5rem;
  &:hover {
    text-decoration: none;
    color: #fff;
    background: #444444;
  }
}

.page-cover{
  position: relative;
  background: url("../img/page-cover-main.jpg") no-repeat top center;
  -webkit-background-size: cover;
  background-size: cover;
  min-height: 600px;
  &:before{
    content: '';
    position: absolute;
    background: url('../img/header-cover.png') no-repeat top center;
    -webkit-background-size: cover;
    background-size: cover;
    display: block;
    width: 100%;
    max-width: 1100px;
    min-height: 670px;
    left: 50%;
    top: -18px;
    transform: translateX(-50%);
  }
}

.page-title, .page-subtitle{
  text-align: center;
}

.page-title__accent{
  color: #cf4d4d;
  font-size: 3rem;
  font-weight: normal;
  font-family: 'Good Vibes Pro';
}

.page-title{
  font-size: 2rem;
  font-weight: bold;
  margin-top: 7.5rem;
  font-family: 'Merriweather';
}

.page-subtitle{
  width: 40%;
  margin: 0 auto;
}

.header-top-line{
  background: url("../img/top-line-cover.jpg") no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.service-card{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  position: relative;
}

.service-card__img{
  width: 85%;
  position: relative;
  img {
    box-shadow: 0 10px 10px rgba(#000, .2);
  }
  &:hover > .service-card__hover{
    opacity: 1;
    visibility: visible;
  }
}

.service-card__hover{
  position: absolute;
  background: rgba(#f6eedc, .7);
  width: 100%;
  padding-top: 4rem;
  height: calc(90% + 5px);
  opacity: 0;
  visibility: hidden;
  transition: .2s;
  ul {
    list-style: none;
    padding: 1rem;
    font-weight: bold;
    font-size: 1.15rem;
  }
}

.service-card__title{
  font-size: 2rem;
  position: absolute;
  left: 0;
  z-index: 5;
  font-weight: bold;
  top: 5%;
  font-family: 'Merriweather';
}

.service-card__btn{
  display: flex;
  justify-content: center;
  position: relative;
  top: -24px;
}

.section {
  padding: 4rem 0;
}

.section-header{
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.section-title{
  width: 100%;
  text-align: center;
  font-family: 'Merriweather';
}

.pluses-row{
  display: flex;
  align-items: top;
  justify-content: space-between;
}

.pluses-item{
  width: 20%;
  padding: 1rem;
  text-align: center;
}

.get-service-info{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  position: relative;
}

.get-service-box{
  width: 45%;
  background: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
  padding: 2rem .5rem;
  transform: scale(1.1);
  .btn {
    margin-top: 1.5rem;
  }
}

.get-service-title{
  font-size: 1.5rem;
}

.accent-title{
  color: #cf4d4d;
  font-family: 'Good Vibes Pro';
}

.get-service-text{
  width: 55%;
  background: #fff;
  padding: 1.5rem;
  box-shadow: 0 5px 10px rgba(#000, .15);
}

.section-get-service{
  padding-bottom: 0;
  background: url("../img/get-service-img.jpg") no-repeat top center;
  -webkit-background-size: cover;
  background-size: cover;
}

.section-title_left{
  text-align: left;
}

.pluses-card{
  background: #fff;
  padding: 2rem;
  position: relative;
}

.pluses-card__num{
  color: #cf4d4d;
  font-weight: bold;
  font-size: 4rem;
  position: absolute;
  left: -16px;
}

.pluses-card__text{
  margin: 0;
}

.section-pluses{
  background: url("../img/pluses-bg.jpg") no-repeat center center;
}

.pluses-card_1{
  width: 70%;
  left: 20%;
  top: 10%;
}

.pluses-card_2{
  width: 80%;
  left: 20%;
}

.pluses-card_3{
  width: 90%;
  top: 30%;
}

.pluses-card_4{
  width: 70%;
  left: 15%;
  top: 25%;
}

.pluses-text{
  margin-top: 10%;
  text-align: center;
  font-size: 1.25rem;
}

.section-our-works{
  background: url("../img/work-bg.png") no-repeat top center;
  -webkit-background-size: cover;
  background-size: cover;
}

.work-card__title{
  color: #fff;
  display: inline-block;
  padding: .5rem;
}

.work-card{
  margin-bottom: 1rem;
}

.works-row{
  margin-top: 1.5rem;
}

.section-title_light {
  color: #ffff;
}

.article{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  margin-bottom: 1.5rem;
}

.article__img{
  width: 25%;
}

.article__content{
  width: 70%;
}

.article__text{
  margin: 0;
}

.article__title{
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
  &:hover{
    color: #cf4d4d;
  }
}

.section-contacts {
  background: url("../img/contacts-bg.jpg") no-repeat top center;
  -webkit-background-size: cover;
  background-size: cover;
}

.contacts-box{
  background: #fff;
  padding: 2rem;
  min-height: 266px;
}

.contacts-item{
  display: flex;
  align-items: center;
}

.contacts-item__text{
  font-size: 1.25rem;
  margin-left: .5rem;
}

.contacts-title{
  margin: 1rem 0rem 1rem 1rem;
}

.c-form__title{
  text-align: center;
  margin-bottom: 1.5rem;
}

.form-control{
  margin-bottom: 1rem;
  &:last-child{
    margin-bottom: 0;
  }
}

.footer-menu{
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  flex-flow: row wrap;
  list-style: none;
}

.footer{
  background: #2d2d2f;
  padding: 2.5rem 0;
}

.footer-menu__link{
  color: #fff;
  &:hover{
    text-decoration: none;
    color: #888888;
  }
}

.footer-copyright, .footer-made-in{
  color: #888888;
  font-size: 14px;
}

.footer-sub-info{
  border-top: 1px solid #bbbbbb;
  padding-top: 1rem;
}

.footer-main-info{
  padding-bottom: 1rem;
}

.footer-made-in{
  display: block;
  text-align: right;
}

.info-box{
  display: flex;
  align-items: flex-end;
  background: #fff;
  padding: 2rem;
  position: relative;
}

.info-box__text{
  width: 70%;
  margin: 0;
}

.section-company-info{
  background: url("../img/info-section-bg.jpg") no-repeat center center;
}

.info-box__img{
  position: absolute;
  right: -10%;
  bottom: 0;
}

.mobile-menu-btn{
  width: 36px;
  height: 36px;
  background: none;
  border: none;
  margin: .5rem 0;
  display: none;
  &:focus{
    outline: none;
  }
  &:active{
    outline: none;
  }
}

.mobile-menu{
  display: none;
  width: 100%;
  ul {
    list-style: none;
    margin: 0;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-bottom: 2rem;
  }
  a {
    color: #fff;
  }
}

.breadcrumbs{
  padding: 1.5rem 0;
  display: flex;
  list-style: none;
  margin: 0;
}

.breadcrumbs__item{
  position: relative;
  &:after{
    content: '>';
    margin: 0 .2rem;
  }
  &:last-child{
    &:after{
      content: '';
    }
  }
}

.breadcrumbs__link{
  color: #8899a5;
  &:hover {
    text-decoration: none;
    color: #8899a5;
  }
}

.page-title_inner{
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-family: 'Merriweather';
}

.product-card{
  text-align: center;
}

.product-card__title{
  font-weight: bold;
  font-size: 1.5rem;
}

.product-card__img{
  position: relative;
  margin-bottom: 1rem;
}

.product-card__price{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #cf4d4d;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  flex-flow: row wrap;
  line-height: 1;
  span {
    font-size: 14px;
  }
}

.section-catalog-inner{
  background: url("../img/catalog-inner.jpg") no-repeat bottom center;
  -webkit-background-size: cover;
  background-size: cover;
}

.product-price-box{
  font-weight: bold;
  line-height: 1;
}

.product-price{
  font-size: 2rem;
  color: #cf4d4d;
}

.product-service-box{
  padding: 1rem 0;
  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      position: relative;
      margin-left: 3rem;
      &:first-child{
        margin-left: 1.5rem;
      }
      &:before {
        content: '';
        position: absolute;
        width: 25px;
        height: 20px;
        background: url("../img/check-i.png") no-repeat center center;
        -webkit-background-size: cover;
        background-size: cover;
        display: block;
        left: -1.5rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.product-pluses-box{
  margin-bottom: 1.2rem;
  p {
    font-weight: bold;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 1.2rem;
      display: inline-flex;
      align-items: center;
      span {
        margin-left: 1rem;
      }
    }
  }
}

.product-table-box{
  table {
    width: 100%;
    background: #fff;
    box-shadow: 0 5px 10px rgba(#000, .1);
    tr {
      display: flex;
      justify-content: space-between;
      padding: .5rem 1.5rem;
      &:nth-child(even) {
        background: #f8f4de;
      }
      td:last-child{
        font-weight: bold;
      }
    }
  }
}

.work-box{
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  display: block;
  &:hover > .work-box__overlay{
    display: flex;
  }
  &:hover{
    text-decoration: none;
    color: gray;
  }

}

.work-box__overlay{
  position: absolute;
  background: rgba(#fff, .8);
  color: #333;
  text-align: center;
  width: 100%;
  height: 100%;
  min-height: 262px;
  display: none;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  max-height: 262.5px;
}

.section-works{
  background: url("../img/works-bg.jpg") no-repeat top center;
  -webkit-background-size: cover;
  background-size: cover;
  padding: 4rem 0;
}

.works-img{
  display: block;
  margin: 0 auto;
}

.work-box__text{
  color: #333;
  text-align: center;
  margin-top: 1rem;
}

.gallery-page{
  background: url("../img/catalog-inner.jpg") no-repeat top center;
  -webkit-background-size: cover;
  background-size: cover;
}

.form-box{
  background: #fff;
  padding: 1rem;
  text-align: center;
}

.form-subtitle{
  text-transform: uppercase;
  font-weight: bold;
  margin-top: .5rem;
}

.contact-item{
  display: flex;
}

.contact-item__title{
  padding-left: 1.5rem;
  margin-bottom: 1rem;
  display: inline-block;
}

.team-card__name{
  font-size: 1.2rem;
  margin-top: 1rem;
  font-weight: bold;
  margin-bottom: 0;
  text-transform: uppercase;
}

.team-card__position{
  color: #8a9aa6;
  margin-bottom: 0;
}

.plus-box{
  width: 100%;
  max-width: 420px;
  display: flex;
  margin-bottom: 2rem;
  &:nth-child(even) {
    margin-left: 10%;
  }
}

.plus-box__desc{
  margin-left: 1rem;
}

.plus-box__title{
  margin-bottom: 0;
  font-weight: bold;
}

.plus-box__num{
  font-size: 72px;
  font-weight: bold;
  color: #cf4d4d;
  line-height: 1;
}

.about-row-2{
  position: relative;
}

.about-row-2-img{
  position: absolute;
  left: 0;
  bottom: 10%;
  z-index: -1;
  max-width: 50%;
}

.about-img-1{
  position: absolute;
  z-index: -1;
  right: 0;
  max-width: 50%;
}

.inner-list{
  margin: 0;
  padding: 0;
  list-style: none;
}

.inner-list__item{
  position: relative;
  margin-left: 2rem;
  &:before{
    content: '';
    position: absolute;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("../img/check-i.png") no-repeat center center;
    -webkit-background-size: cover;
    background-size: cover;
    left: -2rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.inner-info{
  background: url("../img/inner-info-bg.png") no-repeat center center;
  -webkit-background-size: cover;
  background-size: contain;
  color: #fff;
  font-weight: bold;
  padding: 2rem 0;
  font-size: 1.5rem;
  margin: 2.5rem 0;
}

.inner-info__text{
  margin: 0;
}

.inner-list__item_num{
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1rem;
}

.num-item{
  color: #cf4d4d;
  font-size: 1.25rem;
  font-weight: bold;
  margin-right: 1rem;
  width: 10px;
}

.text-item{
  width: 90%;
}

.inner-btn-box{
  position: relative;
}


.inner-btn-box__btn{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10%;
}

.inner-end{
  margin-top: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
}

.modal-form{
  padding-bottom: 3rem;
}

.modal-header{
  border: none;
}

.modal{
  background: url("../img/modal-bg.jpg") no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
}

.modal-content{
  border: none;
  border-radius: 0;
}
@import "./media";